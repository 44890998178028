.ReferenceWrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 80px;
}
.PDFListWrap {
    width: 100%;
    margin: 0 auto;
    /* border: 1px solid #000; */
}
.PDFHead {
    width: 100%;
    padding: 10px 30px;
    border-bottom: 1px solid #000;
    background-color: #3189FF;
    color: #FFF;
    font-size: 24px;
    font-weight: 600;
}
.PDFList {
    display: block;
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    width: 100%;
    padding: 10px 30px;
    border-left: 1px solid #bababa;
    border-right: 1px solid #bababa;
    border-bottom: 1px solid #bababa;
    cursor: pointer;
}
.PDFList p{
    margin-top: 3px;
}
/* .PDFList:last-child {
    border-bottom: none;
} */
.PDFList::after{
    content: "";
    display: block;
    background-image: url(../../../public/images/pdf_download.png);
    background-size:contain;
    background-repeat: no-repeat;
    width: 40px;
    height: 35px;   
    margin-left: 20px;
}
.Modal {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 9;
}
.ModalOutside {
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.35);
}
.ModalInputWrap {
    position: relative;
    width: 100%;
    padding: 50px 30px;
    max-width: 480px;
    background-color: #fff;
    z-index: 999;
}
.ModalInputWrap .ClosedBtn {
    position: absolute;
    top: 15px;
    right: 15px;
}
.ModalInputWrap h2 {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
}
.ModalInputWrap h3 {
    font-weight: 600;
    text-align: center;
}

.ModalInputWrap .InputWrap {
    position: relative;
    margin-top: 30px;
    width: 100%;
    border: 1px solid #3289FF;
    border-radius: 5px;
    padding: 10px 15px;
}
.ModalInputWrap .InputWrap > input {
    width: calc(100% - 30px);
    padding: 0;
    border-radius: 5px;
    background-color: #fff;
}
.ModalInputWrap .InputWrap > input:focus {
    outline: none;
}
@media (max-width : 768px) {
    .ReferenceWrap {
        padding-top: 0;
    }
}