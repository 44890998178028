.Footer {
    width: 100%;
}

.Footer hr{
    margin: 70px 0 45px;
}

.logoWrap {
    display: flex;
}

.logoWrap p{
    width: 100%;
}

.footer_logo {
    width: 178px;
    height: 45px;
    margin-left: -29px;
}

.facebook_logo {
    width: 45px;
    height: 45px;
    float: right;
}

.Footer h3{
    font-size: 12px;
    margin-right: 15px;
}
.Footer p{
    font-size: 12px;
    color: #AFAFAF;
    
}
.Footer span{
    color: #000;
}

.footer_address{
    display: flex;
    justify-content: space-between;    
    border-left: 1px solid #BABABA;
    padding-left: 10px;
    margin-top: 10px;
    line-height: 1.6;
}

.footer_address_1{
    display: flex;
}
.footer_address_1 p ,.footer_address_2 p{
    margin-right: 10px;
}
.footer_address_2{
    display: flex;
}
.copyright{
    margin: 30px 0;
}

@media (max-width: 1000px) {

}
    
