.ProductList {
    padding-top: 30px;
}

.CategoryProductWrap {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 850px;
    margin: 0 auto;
}

.MainCategoryList {
    box-sizing: border-box;  
    display: block;
    width: 223px;
    height: 260px;
    margin-left: 50px;
    margin-bottom: 10px;
    padding: 10px;
    color: black;
    cursor: pointer;
    /* box-sizing: border-box; */
}

.MainCategoryList:hover {    
    border: 3px solid #3289FF;
    border-radius: 10px;
}
.CardImgWrap {
    width: 100%;
    height: 190px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #EDEDED;
    
}
.CardImgWrap img{
    /* height: 100%; */
    object-fit: contain;
}
.MainCategoryList div{
    line-height: 1.8;
    margin-top: 5px;
    font-size: 16px;
    font-weight: 600;
}
.MainCategoryList p{
    font-weight: normal;
    margin-bottom: 12px;
}

.ControlBarNone {
    display: none;
    width: 244px;
    padding-top: 10px;
}

.ProductList .Wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.ProductList .Wrap .ControlBar {
    width: 244px;
    padding-top: 10px;
}
.ProductList .Wrap .ControlTitle {
    width: 100%;
    padding: 10px 20px;
    font-size: 18px;
    font-weight: 600;
    background-color: #1289FF;
    color: #FFF;
}
.ProductList .Wrap .Category {
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    padding: 10px 20px;
    border: 1px solid #EAEAEA;
    cursor: pointer;
}
.ProductList .Wrap .CategoryActive {
    color: #1289FF;
    border-bottom: none;
    position: relative;
}
.ProductList .Wrap .CategoryActive::after {
    content: '';
    position: absolute;
    left: 5%;
    bottom: 0;
    width: 90%;
    height: 1px;
    background: #1289FF;
}
.ProductList .Wrap .subCategory {
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    padding: 15px 35px;
    border-left: 1px solid #EAEAEA;
    border-right: 1px solid #EAEAEA;
    cursor: pointer;
    position: relative;
}
.ProductList .Wrap .subCategoryActive {
    color: #1289FF;
}
.ProductList .Wrap .subCategory p::before{
    content: '';
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 5px;
    height: 5px;
    border-radius: 50px;
    background: #000;
}
.ProductList .Wrap .Products {
    width: calc(100% - 250px);
}
.ProductList .Wrap .ProductsWrap{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.ProductList .Wrap .CardWrap {
    width: 33.3%;
}
.ProductList .Wrap .MainCategoryList {
    width: 33.3%;
}
.ProductList .Wrap .ProductImgWrap {
    width: 212px;
    height: 188px;
    overflow: hidden;
    border: 1px solid #EAEAEA;
    box-sizing: border-box;
}

.ProductList .Wrap .ProductImgWrap img {
    height: 100%;
}
.ProductList .Wrap .ProductContentsWrap{
    height: 120px;
    overflow: hidden;
}
.ProductList .Wrap .ProductName {
    font-size: 16px;
    font-weight: 600;
    margin: 10px 0;
}
.ProductList .Wrap .ProductContent {
    font-size: 15px;
    font-weight: 400;
}
@media (max-width: 958px){
    .ProductList .Wrap .ControlBar{
        width: 200px;
    }
    .ProductList .Wrap{
        justify-content: center;
    }
    .ProductList .Wrap .ProductsWrap{
        justify-content:unset;
    }
    .ProductList .Wrap .Products{
        width: 100%;
    }
    .Products {
        width: 100%;
    }

}
@media (max-width: 865px){
    .ProductList .Wrap .ControlBar{
        width: 330px;
    }
    .ProductList {
        padding-top: 0px;
    }
    .CategoryProductWrap {
        justify-content: space-evenly;
        width: 100%
    }
    .MainCategoryList {
        margin: 0;
        padding: 0;
        width: 45%;
        height: auto;
        margin-bottom: 20px;
        text-align: center;
    }
    .MainCategoryList div {
        margin-bottom: 5px;
    }

}

@media (max-width: 500px) {
    .ProductList {
        padding-top: 0px;
    }
    .ProductList .Wrap{
        display: block;
        margin: 0 auto;
    }
    .ProductName{
        margin-bottom: 10px;
    }
    .ProductList .Wrap .ControlBar {
        width: 100%;
        margin-bottom: 20px;
    }
    .ProductList .Wrap .Products{
        width: 100%;
        display: block;
        margin: 0 auto;
        padding: 10px;
    }
    .ProductList .Wrap .ProductImgWrap{
        width: 100%;
        margin-bottom: 5px;
        margin-top: 7px;
    }
    .ProductList .Wrap .ProductsWrap{
        display: block;
        margin: 0 auto;
    }
    .CategoryProductWrap {
        display: flex;
        width: 100%;
        margin: 0 auto;
    }
    .ProductList .Wrap .Product{
        display: block;
        width: 100%;
        height: auto;
        margin: 0 auto;
        padding: 0;
    }
    .ProductList .Wrap .ProductContentsWrap{
        height: auto;
    }
    .ProductList .Wrap .ProductName{
        margin-bottom: 10px;
    }
    .Footer hr{
        margin-top: 20px;
    }
}
@media (max-width : 768px) {
    .ProductList .Wrap .CardWrap {
        width: 100%;
    }
}