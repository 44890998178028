.ProductEditor .Wrap {
    display: flex;
    flex-direction: column;
}
.ProductEditor .Wrap .labelInput{
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}
.ProductEditor .EditorLabel {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
}
.ProductEditor .EditorInput {
    border: 1px solid #777;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 400;
}
.ProductEditor textarea {
    border: 1px solid #777;
    border-radius: 5px;
    padding: 10px 20px;
}
.ProductEditor select {
    border: 1px solid #777;
    border-radius: 5px;
    padding: 10px 20px;
}
.ProductEditor .ProductEditorBtn {
    background-color: #1289FF;
    color: #FFF;
    padding: 10px 0;
    border-radius: 5px; 
}
.ProductEditor .ImgLists {
    display: flex;
    margin-bottom: 30px;
}
.ProductEditor .ImgWrap {
    width: 200px;
    height: 200px;
    border: 1px solid #000;
    margin-right: 30px;
}
.PDFInput {
    display: none;
}
.uploadPDF {
    text-align: center;
    width: 150px;
    box-sizing: border-box;
    padding: 5px 10px;
    border: 1px solid #000;
    border-radius: 5px;
    background-color: #EDEDED;
    cursor: pointer;
}
.PDFDeleteWrap {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.deletePDF {
    font-weight: 600;
    color: red;
    box-sizing: border-box;
    margin-left: 10px;
    cursor: pointer;
}