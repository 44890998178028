@font-face {
    font-family: 'Fuzzy';
    src: url('../../assets/fonts/FuzzyBubbles-Regular.woff') format('woff');
   
    font-family: 'Inkfree';
    src: url('../../assets/fonts/Inkfree.woff') format('woff');
   
}

#About_Wrap {
    font-family: 'Inkfree';
}
.About_h3{
    width: 100%;
    height: 202px;
    color:#fff;
    line-height: 202px;
    background-image: url(../../../public/images/about.png);
    background-size: cover;
    background-repeat: no-repeat;
    font-size: 40px;
    text-indent: 44px;
    margin-bottom: 26px;
}
#About_Wrap p {
    font-size: 28px;
}
.About_p1{
    font-size: 28px;
    margin-top: 26px;
    margin-bottom: 52px;font-family: "Inkfree";
}

.About_p2{
    font-size: 30px;
    font-family: "Inkfree";
}
.About_line{
    width: 122px;
    margin-top: 13px;
    margin-bottom: 50px;
    border-top: 1px solid #707070;
}
.CatalogsBtn {
    font-size: 24px;
    font-weight: 600;
    display: block;
    width: 100%;
    max-width: 980px;
    margin: 0 auto;
    padding: 30px;
    color: #FFF;
    background-color: #3189FF;
}
.CatalogsBtn span {
    float: right;
}
@media (max-width: 979px) {
    .About_Wrap{
        width: 100%;
        margin: 0 auto;
        padding: 10px;
    }
}
@media (max-width: 500px){
    .About_h3{
        height: 101px;
        line-height: 101px;
    }
    .About_line{
        margin-bottom: 30px;
    }
}