.Header {
    width: 100%;
    height: 112px;
}
.Header .Wrap {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 20px 20px 28px;
}
.Header .Wrap .SidebarBtn {
    display: none;
}
.Header .Wrap .Logo{
    width: 178px;
}

.MenuList {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Header .Wrap .Menu {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-left: 40px;
    font-size: 16px;
    font-weight: 400;
    position: relative;
    cursor: pointer;
}

.Header .Wrap .CompanySubMenu {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    background-color: red;
    padding: 20px 0;
    position: absolute;
    top: 60px;
    width: 85px;
    height: auto;
    border-left: 1px solid #a8a8a8;
    border-right: 1px solid #a8a8a8;
    border-bottom: 3px solid #7c808f;
    padding: 0 10px;
    background: #fff;
}
.Header .Wrap .CompanyMenu:hover .CompanySubMenu{
    display: flex;
}
.Header .Wrap .ProductsSubMenu {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    background-color: red;
    padding: 20px 0;
    position: absolute;
    top: 60px;
    width: 285px;
    height: auto;
    border-left: 1px solid #a8a8a8;
    border-right: 1px solid #a8a8a8;
    border-bottom: 3px solid #7c808f;
    padding: 0 10px;
    background: #fff;
}

.Header .Wrap .ProductsMenu:hover .ProductsSubMenu{
    display: flex;
}
.Header .Wrap .SubMenu {
    margin: 10px 0;
}
.Header .Wrap .SubMenu:hover {
    color: blue;
    transition: all 0.3s;
}   
@media (max-width : 768px) {
    .Header {
        height: 70px;
    }
    .Header .Wrap {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 16px;
        position: relative;
    }
    .Header .Wrap .SidebarBtn {
        display: block;
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translate(0%, -50%);
        font-size: 20px;
        font-weight: 600;
        color: #000;
    }
    .Header .Wrap .Logo{
        width: 130px;
    }
    .MenuList {
        display: none;
    }
    .Search {
        display: none;
    }
}