.ContactWrap {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding-top: 50px;
}

.ContactBanner {
    width: 100%;
    background-image: url('../../assets/images/ContactBanner.png');
    padding: 50px 30px; 
    color: #FFF;
}
.ContactBanner > h3{
    font-size: 40px;
    margin-bottom: 20px;
}
.ContactBanner > p {
    font-size: 16px;
    line-height: 1.75em;
}
.ContactInputWrap {
    width: 100%;
    margin-bottom: 30px;
}

.ContactDivision {
    width: calc(50% - 20px);
}
.ContactInputTitle {
    margin-bottom: 20px;
}
.ContactInputSubWrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 30px;
}
.ContactInputSubWrap > div{
    width: calc(50% - 10px);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
}
.ContactInputSubWrap > div > input {
    width: 100%;
}

.ContactInputDivision {
    width: calc(50% - 20px);
    border: 1px solid #777;
    background-color: #EDEDED;
    border-radius: 5px;
    padding: 7px 15px;
    font-size: 16px;
}
.ContactInput {
    width: 100%;
    border: 1px solid #777;
    background-color: #EDEDED;
    border-radius: 5px;
    padding: 7px 15px;
    font-size: 16px;
}
.ContactTextArea {
    width: 100%;
    height: 382px;
    max-height: 382px;
    border: 1px solid #777;
    background-color: #EDEDED;
    border-radius: 5px;
    padding: 7px 15px;
    font-size: 16px;
    resize: none;
}
.SendBtn {
    float: right;
    color: #FFF;
    border: 1px solid #777;
    background-color: #184D9D;
    border-radius: 5px;
    padding: 7px 15px;
    font-size: 16px;
}
.SendBtn:hover {
    opacity: 0.8;
    transition: all 0.3s;
}
.ContactTitle {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 30px;
}
.ContactText {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
}

@media (max-width: 939px){
    .Contact{
        width: 100%;
        justify-content: space-evenly;
    }
    
}

@media (max-width: 500px){
    .Contact{
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        padding: 30px 0;
    }
    .ContactDivision {
        width: 95%;
        margin: 0 auto;
        margin-bottom: 50px;
    }
    .SendBtn{
        padding: 6px 15px;
    }
    .ContactText{
        margin-bottom: 30px;
    }
}
@media (max-width: 768px){
    .ContactWrap {
        padding: 30px 16px;
        flex-direction: column;
    }
    .ContactDivision {
        width: 100%;
        margin-bottom: 10px;
    }
}
