.AdminControllBar {
    display: flex;
    width: 100%;
    margin-bottom: 80px;
}
.AdminControllBar a {
    display: block;
    width: 50%;
    border: 1px solid #EAEAEA;
    padding: 15px 0;
    text-align: center;
}
.AdminControllBar .Active {
    background-color: #1289FF;
    color: #FFF;
}