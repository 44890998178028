* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
ol, ul {
	list-style: none;
}
h1, h2, h3, h4, h5, h6, p {
  margin: 0;
	padding: 0;
}

@font-face {
  font-family: 'Pretendard-Regular';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard';
  src: url('./assets/fonts/Pretendard-Medium.woff') format('woff'),
   url('./assets/fonts/Pretendard-Medium.woff2') format('woff2');
  font-family: 'Pretendard_Bold';
  src: url('./assets/fonts/Pretendard-Bold.woff') format('woff'),
  url('./assets/fonts/Pretendard-Bold.woff2') format('woff2');
}
/* Common */

body {
  font-family: 'Pretendard-Regular','Pretendard','Pretendard_Bold';
  
}

.Container {
  padding-top: 80px;
  padding-bottom: 80px;
}
.Wrap {
  width: 100%;
  max-width: 980px;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  margin: 0 auto;
}

.App {
  position: relative;
}
.Hide {
  display: none;
}
.Red {
  color: red;
}