.ProductAdmin .ProductsWrap {
    border: 1px solid #777;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 30px;
}
.ProductAdmin .ProductsWrap .Product{
    background-color: #EDEDED;
    color: #000;
    margin-bottom: 10px;
    padding: 10px 20px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}
.ProductAdmin .ProductBtn button {
    margin: 0 10px;
    cursor: pointer;
}
.ProductAdmin .ProductBtn button:hover {
    color: #1289FF;
}
.ProductAdmin .CreateBtn {
    display: block;
    width: 100%;
    background-color: #1289FF;
    color: #FFF;
    border-radius: 10px;
    padding: 10px 0;
    text-align: center;
}