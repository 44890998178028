.Quote {
    position: fixed;
    top: 100px;
    left: 0;
    display: flex;
    transition: all 0.3s;
}
.QuoteClosed {
    margin-left: -300px;
}
.QuoteOpen {
    margin-left: 0px;
}
.QuoteContent {
    display: flex;
    width: 300px;
    /* height: 500px; */
    background-color: #3289FF;
    flex-direction: column;
    padding: 30px 25px;
    box-sizing: border-box;
}
.QuoteContent label{
    color: #FFF;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
}
.QuoteContent input {
    width: 100%;
    padding: 5px 10px;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 20px;
    border-radius: 5px;
}
.QuoteContent button {
    width: 100%;
    padding: 5px 10px;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 20px;
    background-color: #184D9D;
    color: #FFF;
    border-radius: 5px;
    margin-top: 10px;
}
.QuoteBtn {
    cursor: pointer;
    display: flex;
    width: 60px;
    height: 100px;
    background-color: #3289FF;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.QuoteBtn p {
    color: #FFF;
    font-weight: 600;
    transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
}
@media (max-width : 768px) {
    .Quote {
        display: none;
    }
}