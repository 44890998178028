.Card {
    display: block;
    width: 100%;
    height: 260px;
    padding: 10px;
    box-sizing: border-box;
}
.Card:hover {
    border: 1px solid #3189FF;
    border-radius: 5px;
    transition: all 0.3s;
} 
.CardImgWrap {
    width: 100%;
    height: 190px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #EDEDED;
}
.CardImgWrap > img {
    height: 100%;
    object-fit: contain;
}
.CardContentsWrap {
    width: 100%;
    padding: 10px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}