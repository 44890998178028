.Product .Wrap {
    display: flex;
}
.Product .Wrap .Left{
    width: calc(50% - 30px);
    margin-right: 15px;
    border: 1px solid #EDEDED;
    padding: 20px;
}
.Product .Wrap .Right{
    width: calc(50% - 30px);
    margin-left: 15px;
}
.ProductImgWrap {
    width: 100%;
    height: auto;
    overflow: hidden;
    margin-bottom: 30px;
}
.ProductImgWrap img{
    width: 100%;
}
.ImgControllerWrap {
    display: flex;
    width: 100%;
    padding: 20px 0;
    border-top: 1px solid #EDEDED;
}
.ImgController {
    width: calc(20% - 10px);
    margin: 5px;
    overflow: hidden;
}
.ImgController:hover {
    opacity: 0.7;
    transition: all 0.3s;
}
.ImgController img{
    width: 100%;
    cursor: pointer;
}
.ImgController img{
    width: 100%;
    cursor: pointer;
}
.ProductName {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
}
.ProductContent {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 20px;
    white-space: pre-line;
    line-height: 2.0;
}
.ProductPDF {
    display: block;
    width: 100%;
    padding: 10px 0;
    text-align: center;
    background-color: #777;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 400;
}
.RequestWrap {
    margin-top: 50px;
    width: 100%;
    padding: 10px 0;
    background-color: #3289FF;
    color: #FFF;
    border-radius: 25px;
    text-align: center;
    cursor: pointer;
}
.RequestInputWrap {
    margin-top: 50px;
    width: 100%;
    padding: 1px 30px;
    border: 1px solid #3289FF;
    border-radius: 25px;
    text-align: center;
    box-sizing: border-box;
    position: relative;
}
.RequestInputWrap > input {
    width: 100%;
    padding: 9px 0;
    text-align: center;
    box-sizing: border-box;
}
.RequestInputWrap > input:focus {
    outline: none;
}
.PaperPlane {
    color: #3289FF;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(-50%, -50%)
}
@media (max-width: 939px){
    .Product .Wrap .Left{
        width: calc(55% - 30px);
    }
    .Product .Wrap .Right{
        width: calc(45% - 30px);
    }
    .ImgController{
        width: calc(24% - 10px);
    }
}


@media (max-width: 500px) {
    .Product .Wrap{
        display: block;
        width: 100%;
    }
    .Product .Wrap .Left{
        width: 100%;
    }
    .ImgController{
        width: 24%;
        margin-bottom: -16px;
    }
    .Product .Wrap .Right{
        width: 90%;
        margin-top:15px;
        padding: 10px;
    }
    .ProductContent{
        width: 100%;
        margin: 0 auto;
    }
    .Wrap_flex {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }
    .product { 
        width: 46%;
       border: 1px solid #eee;
       border-radius: 10px;
       margin-bottom: 11px;
       box-sizing: border-box;
      
    }
}
