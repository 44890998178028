.AdminBar {
    width: 100%;
    height: 50px;
    background-color: #1289FF;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    color: #FFF;
    font-weight: 600;
    cursor: pointer;
}
.AdminBar h2 {
    font-size: 20px;
}
.AdminBar button {
    position: absolute;
    top: 50%;
    right: 50px;
    transform: translate(-50%, -50%);
}
.AdminBar button:hover {
    opacity: 0.7;
    transition: all 0.2s;
}