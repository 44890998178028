
/* section1 */
.Main_txt{
    position: relative;
    max-width: 940px;
    margin: 0 auto;
    color: #fff;
    bottom:135px
}
.Main_txt h2{
    font-size: 32px;
}

.Main_txt h3{
    font-size: 26px;
}

 .Main_bg{
    margin-bottom: -50px;
 }

.Wrap{
    width: 980px;
    padding: 0;
}
.Wrap2{
    width: 940px;
    margin: 0 auto;
}
.Wrap_flex{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.Main_img{
    display: block;
}
.Main_img_m{
    display: none;
}

/* section2 */
.MainCategoryPreview {    
    width: 223px;
    height: 238px;
    margin-bottom: 50px;
    padding: 10px;
    cursor: pointer;
    margin: 0 auto;
    /* box-sizing: border-box; */
}
.MainCategoryPreview:hover {    
    border: 3px solid #3289FF;
    border-radius: 10px;
}
.MainCategoryPreview img{
    height: 100%;
}
.MainCategoryPreview div{
    line-height: 1.8;
    margin-top: 5px;
    font-size: 16px;
    font-weight: 600;
}
.MainCategoryPreview p{
    font-weight: normal;
    margin-bottom: 12px;
}


/* 제품더보기 more  */
.more{
    display: block;
    width: 223px;
    height: 188px;
    background-color: #3189FF;
    color: #fff;
}

.more h2,.more h3 ,.more p{ 
    padding: 0 22px;
}
.more h2 {
    font-size: 20px;
    padding-top: 10px;
    font-weight: 600;
    
}
.more h3{
    font-size: 16px;
    padding-top: 4px;
}
.more p{
    font-size: 15px;
    padding-top: 35px;
}
.more_menu li{
    height: 46px;
    font-size: 16px;
    font-weight: 600;
    border: 1px solid #EAEAEA;
    box-sizing: border-box;
    padding: 11px 18px;
}
.product p:last-child{
    margin-bottom: 32px;
}

/* section3 */
.section_con{
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
   
}
.section_con img{
    width: 460px;
    height: 166px;
    background-color: #ccc;
}
.section_con div{
    width: 460px;  
}
.section_con_2 h2{
    font-size: 33px;
    font-weight: 600;
  
    margin-bottom: 17px;
}
.section_con_2 p{
    font-size: 16px;
    margin-top: 13px;
    line-height: 1.2;
}

/* section4 */
.section_con_3 {
    width: 460px;
    height: 166px;
    margin-top: 10px;
    background-color: #3189FF;
    color: #fff;
    padding: 11px 19px;
}
.section_con_3 h2{
    font-size: 33px;
    font-weight: 600;
}
.section_con_3 p{
    margin-top: 18px;
    font-size: 16px;
    line-height: 1.2;
}
.section_con_4{
    position: relative;
    display: flex;
    width: 100%;
    height: 90px;
    margin-top: 20px;
    padding: 20px;
    background-color: #EAEAEAff;
}
.section_con_4_1{
    color: black;
    font-size: 18px;
}
.section_con_4_2{
    display: flex;
    justify-content: space-between;
    position: absolute;
    right: 22px;
    width: 240px;
    height: 46px;
    font-size: 16px;
    margin-top: 2px;
    background-color: #3189FF;
    color: #fff;
    padding: 12px 15px;
}
.section_con_4_2 span{
    font-weight: 600;
}

/* section5 */
.section_con_5{
    position: relative;
    width: 100%;
    height: 344px;
    background-image: url(./../../assets/images/Tk_About_us.png);
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: 40px;
    margin-bottom: 20px;
    padding: 20px 26px;
}
.section_con_5_1{
    color: #fff;
    margin-top: 200px;
}
.section_con_5_1 h3{
    font-size: 30px;
}
.section_con_5_1 p{
    font-size: 18px;
}
.section_con_5_2{
    display: flex;
    justify-content: space-between;
    position: absolute;
    right: 22px;
    width: 240px;
    height: 46px;
    font-size: 16px;
    margin-top: -53px;
    background-color: #3189FF;
    color: #fff;
    padding: 12px 15px;
}

/* 퀵메뉴 */
.Quote{
    z-index: 1000;
}
/* KKW */
.Section02 {
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
}

.CardWrap {
    width: 25%;
    margin-bottom: 10px;
}
.MoreBtnWrap {
    width: 25%;
    padding: 10px;
    height: 240px;
    box-sizing: border-box;
    margin-bottom: 10px;
    cursor: pointer;
}

.MoreBtnWrap:hover {
    opacity: 0.7;
    transition: all 0.3s;
}
.MoreBtn {
    display: flex;
    color: #FFF;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;
    background-color: #3189FF;
    padding: 20px;
}
.MoreBtn > h3 {
    font-size: 24px;
    font-weight: 600;
}

.MoreBtn > p {
    font-size: 15px;
    font-weight: 400;
}
@media (max-width: 939px) {
    /* 화면 100% */
     .Wrap_m , .Wrap , .Wrap2{
        
        width: 100%;
        margin: 0 auto;
    }
    .section_con img{
        width: 100%;
        padding: 0;
        margin: 0;
    }

    /* section1  */
    .Main_bg{
        margin-bottom: -80px;
    }
    .Main_img{
        display: none;
    }
    .Main_img_m{
        display: block;
        width: 100%;
    }
    .Main_txt{
        width: 100%;
        padding: 10px;
        bottom: 172px;
    }
 

    /* section2 제품나열 */

    .product{
        width: 40%;
        padding: 10px;
    }
    .product img{
        width: 100%;
    }
    /* .product p{
       
    } */
    .product .product_Tit{
        display:block;
    }

    .more_s{
        width: 40%;
        padding: 10px;
    }
    .more{
        width: 100%;
    }
    .more h3{
        margin-top: 10px;
    }
    /* .more p{
     
    } */
    .more_menu{
        width: 100%;
    }
    .Wrap_flex{
        justify-content: space-evenly;
    }

    /* section3 */
    .section_con{
        display: flex;
        width: 100%;
        padding: 10px;
        margin-top: 0;
    }
    .section_con div{
        width: 100%;
    }
    .section_con_2{
        border: 1px solid #eee;
        padding: 10px;
    }
    .section_con_3{
        display: block;
        width: 100%;
        height: 154px;
        margin: 0px 6px 10px;
    }
    .section_con_3 p{
        margin-top: 3px;
    }

    /* section4 */
    .section_con_4{
        display: block;
        height: 185px;
    }
    .section_con_4_2{
        position: unset;
        margin: 16px auto;
    }

    /* section5 회사소개 */
    .section_con_5{
        height: 220px;
        background-size: cover;
        padding: 0 15px;
        margin: 0;
    
    }
    .section_con_5_1{
        padding-top: 10px;
        margin: 0;
    }
    .section_con_5_2{
        position: unset;
        margin: 17px auto;
    }

    /* footer */
    .footer_address{
        display: block;
        border: none;
    }
    .footer_logo img{
        margin-left: 15px;
    }
    .footer_address_1, .footer_address_2{
        display: block;
        margin-left: 10px;
    }
    .footer_address_1_2 span {
        display: block;
        width: 151px;
        margin-left: 10px;
    }
    .copyright {
        margin-left:20px;
    }
    

  }

  @media (max-width: 589px) {
  
    .Main_bg{
        margin-bottom: -108px;
    }
    .product{
        width: 50%;
        box-sizing: border-box;
    }
    .more_s{
        width: 46%;
        padding: 2px;
        
    }
    .more_s h2{
        font-size: 17px;
        padding: 10px  10px 0;
    }
    .more_s h2 span{
       position: absolute;
       margin-left: -9px;
    }
    .more_s h3{
        display: none;
    }

    .more_menu li{
        height: auto;
        font-size: 13px;
        line-height: 22px;
        padding: 11px 10px;
    }
    .more p{
        padding-top: 0px;
        margin-top: 17px;
        padding: 0 10px;
    }

  
    /* section3 */
    .section_con{
        display: block;
        width: 100%;
        padding: 10px;
        margin: 0 auto;
    }
    .section_con_3{
        height: auto;
        margin: 5px 0 15px ;
    }
  }

@media (max-width : 900px) {
    .MainCategoryPreview {
        width: 50%;
    }

    .MoreBtnWrap {
        width: 50%;
        height: 210px;
        margin-top: 5px;
    }
}


@media (max-width : 768px) {
    .Main_txt h3 {
        font-size: 16px;
    }
    .CardWrap {
        width: 50%;
        margin-bottom: 10px;
    }
    .MoreBtnWrap {
        width: 50%;
    }
    .MoreBtn > h3 {
        font-size: 18px;
        font-weight: 600;
    }
    .MoreBtn > p {
        font-size: 14px;
        font-weight: 600;
    }
}

@media (max-width : 600px) {
    .Section02{
        display: block;
    }
    .MainCategoryPreview {
        width: 100%;
    }
    .MoreBtn {
        width: 100%;
    }
}