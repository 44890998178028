.Category .Wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.Category .Wrap > div {
    width: calc(50% - 50px);
}
.CategoryTitle {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 30px;
}
.CategoryWrap {
    width: 100%;
    height: 500px;
    padding: 10px 20px;
    box-sizing: border-box;
    border: 1px solid #979797;
    border-radius: 10px;
}
.CategoryList {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 20px;
    border-radius: 10px;
    background-color: #EDEDED;
    color: #000;
    margin-top : 10px;
    margin-bottom : 10px;
    cursor: pointer;
}
.Category .CategoryListActive{
    background-color: #979797;
}
.CategoryList:hover {
    background-color: #cbcbcb;
    transition: all 0.2s;
}
.CategoryList button:hover{
    color: red;
}
.CategoryInputWrap {
    position: relative;
    top: -20px;
    width: 100%;
    height: 45px;
    padding: 10px 20px;
    border: 1px solid #979797;
    border-radius: 10px;
    background-color: #EDEDED;
    margin-top: 30px;
}
.CategoryInputWrap input {
    width: calc(100% - 20px);
    background-color: inherit;
    font-size: 16px;
}
.CategoryInputWrap input:focus {
    outline: none;
}
.CategoryInputWrap button {
    position: relative;
    background-color: #1289FF;
    color: #FFF;
    top: 375px;
    left: -23px;
    width: calc(100% - 20px);
    height: 50px;
    font-size: 16px;
    font-weight: 600;
    margin: auto 30px;
    border: 1px solid black;
    border-radius: 5px;
}
.CategoryInputWrap button:hover {
    opacity: 0.7;
    transition: all 0.2s;
}
.ImgLists {
    position: relative;
    top: 15px;
    display: flex;
    width: 100%;
    height: 315px;
    margin-bottom: 30px;
}
.ImgWrap {
    position: relative;
    top: -10px;
    width: 100%;
    height: 315px;
    border: 1px solid #000;
    /* margin-right: 30px; */
}
.ImgWrap img {
    margin: auto;
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-fit: contain;
}
@media (max-width : 768px) {
    .Category .Wrap {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0 16px;
    }
    .Category .Wrap > div {
        width: 100%;
        margin-bottom: 50px;
    }
    .ProductAdmin {
        padding: 0 16px;
    }
    .ProductAdmin .ProductsWrap .Product {
        display: flex;
        flex-direction: column;
    }
    .ProductEditor {
        padding: 0 16px;
    }
}
